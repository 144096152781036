<template>
    <Toast position="bottom-right"/> 
    <div class='resultados tw-w-full tw-flex tw-justify-center tw-h-full  tw-items-center tw-p-2'>
        <Card style='background:none;box-shadow:none;' class='animate tw-w-full tw-h-full! p-shadow-5'>
            <template #title>
                <div class='tw-bg-gray-50 tw-rounded tw-py-4 tw-shadow-lg'>
                    <h3>Resultados</h3>
                    <span class='tw-text-sm'>Es IMPORTANTE que la interpretación de estos Resultados la realices junto a tu médico.</span>
                </div>
            </template>
            <template #content>
                <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                    <SelectButton @change="handleTipoChange" size="small" :options="tipos" v-model="tipoSeleccionado" aria-labelledby="basic" />
                </div>
                <div ref='contenedorResultados' v-if='evolucionesList?.length > 0' style='max-height:calc(100vh - 34vh);' class='tw-w-full tw-grid xl:tw-grid-cols-2 tw-gap-2 tw-mx-auto tw-mt-2 tw-bg-gray-100 tw-overflow-auto tw-p-2 tw-rounded'>
                    <resultados-card  v-for="item in evolucionesList" :key="item" @ver='handleVerClick(item)' @visualizar="handleVisualizar(item)" :datos='item'></resultados-card>  
                </div>
                <div v-else style='max-height:calc(100vh - 35vh)' class='tw-w-full tw-mt-2 tw-bg-gray-50 tw-overflow-auto tw-p-2 tw-space-y-2 tw-rounded'>
                    <span>No se encontraron resultados.</span>
                </div>
            </template>
        </Card>

    </div>
    <!--  -->
    <Dialog v-model:visible="resultadoFormActive" class="popup-visorEstudios" :style="{'width':'100%','height':'100%'}">
        <template #header> <div class='tw-w-full'></div> </template>
        <div v-if='resultadoFormActive' class='tw-w-full tw-h-full'>
            <iframe :src="evolucionSeleccionada?.pac?.estudio?.visor" frameborder="0" width="100%" height="100%"></iframe>
        </div>
    </Dialog>

</template>

<script lang='ts'>
import { defineComponent, ref, onBeforeMount, computed ,watchEffect} from 'vue'
import { useToast } from "primevue/usetoast";

import ResultadosCard from '@/components/ResultadosCard.vue'
import axios from '@/plugins/axios';
import moment from 'moment';

import { useStore } from 'vuex';
import { jsPDF } from 'jspdf';

import Pac from '@/modules/Pac'


import { imprimirHC } from '@/hooks/useImpresion';

const PUBLIC_ARCHIVE_TYPES = ["LAB", "RX", "TC", "EC", "QX", "AP", "PAP", "RMN",'EDC','ESC','EVC','EDV','EDA','HOL','MAP','ERC','TCM', 'CEN'];
type PublicArchiveTypes = "LAB" | "RX" | "TC" | "EC" | "QX" | "AP" | "PAP" | "RMN" | 'EDC'| 'ESC'| 'EVC'| 'EDV'| 'EDA'| 'HOL'| 'MAP'| 'ERC'| 'TCM' | 'CEN';

export default defineComponent({
    components:{
        ResultadosCard
    },
    setup: () => {
        const toast = useToast();
        const store = useStore();
        const contenedorResultados = ref<HTMLDivElement>();
        const resultadoFormActive = ref(false);
        
        const medicos = ref([{nombre:'Patricio Esponja'}]);
        const medicoSeleccionado = ref(null);
        const especialidades = ref([{nombre:'Pediatria'}]);
        const especialidadSeleccionada = ref(null);
        const fecha = ref([new Date()]);

        const tipos = ref(['Todo', 'Estudios', 'Imagenes']);
        const tipoSeleccionado = ref('Todo');

        
        const evolucionesList = ref();
        const evolucionSeleccionada = ref();
        const pagina = ref(1);
        const itemsPorPagina = ref(10);
        const cantidadResultados = ref();
        
        
        const handleTipoChange = () => {
            pagina.value = 1;
            obtenerRegistros(true)
        }


        const handleVerClick = async (item:any) =>{
            try {

                const pdfjs =  new jsPDF({
                    format: 'a4',
                    unit:'pt',
                    hotfixes:["px_scaling"]
                });

                if(item.tipo === 'LABO'){
                    getImpresionLabo(item);
                    return;
                }
                
                const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
                evolucionSeleccionada.value = result.data;

                if(evolucionSeleccionada.value.datos?.file?.location){
                    downloadArchive(evolucionSeleccionada.value.datos.file)
                    return;
                }


                
                
                

                if(evolucionSeleccionada.value.tipo === 'PAC'){
                    const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
                    await downloadPacArchive(pac);
                    return;
                }

                if(!evolucionSeleccionada.value.medico?.logofirma){
                    toast.add({severity:'warn',summary:'Atencion',detail:'Resultado sin firma.',life:1500})
                    return;
                }
                
                await imprimirHC(evolucionSeleccionada.value,pdfjs, store.state.configuracionPortal.cliente);
                pdfjs.save(`${evolucionSeleccionada.value.datos.nombre} - ${moment(evolucionSeleccionada.value.datos.fecha).format('DD_MM_YYYY')}.pdf`);
            } catch (error) {
                console.log(error);
            }
        }

        const getImpresionLabo = async (item:any) => {
            try {
               const { data } = await axios.post('/lab-estudio/imprimir', {filtros:{idestudio:item.id}})
               
               
                await consultPrintStatus(data.uuid);

                await getPdfEstudio(data.uuid);

            } catch (error) {
                console.log(error);
            }
        }

        const getPdfEstudio = async (uuid:string) => {
            const pdf = await axios.get('/lab-estudio/get-impresion',{params:{uuid},responseType:'blob'});
            let reader = new FileReader();
            reader.readAsDataURL(pdf.data);
            reader.onloadend = () => {
                let base64data = reader.result;
                let link = document.createElement('a');
                link.href = base64data as string;
                link.download = `Estudio de Laboratorio` + '.pdf';
                link.click();
            }
        }

        const consultPrintStatus = async (uuid:string) => {
            let timer:any;
            await new Promise((resolve,reject) => {
                timer = setInterval(() => {
                    axios.get('/lab-estudio/get-impresion-status',{params:{uuid}})
                    .then(({data}) => {
                        if(data.status === 'finished'){
                            clearInterval(timer);
                            resolve(data);
                        }
                        if(data.status === 'error'){
                            clearInterval(timer);
                            reject(data);
                        }
                    }).catch((error) => {
                        clearInterval(timer);
                        reject(error);
                    })
                }, 1000);
            })

            timer && clearInterval(timer);
        }

        const handleVisualizar = async (item:any) => {
            const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
            evolucionSeleccionada.value = result.data;
            const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
            evolucionSeleccionada.value.pac = pac;
            resultadoFormActive.value = true;
        }

        const downloadArchive = (file:any) => {
            let link = document.createElement('a');
            link.href = file.location;
            link.download = file.name;
            link.click();
        }


        const downloadPacArchive = async (estudio:any) => {
            const { data } = await axios.get('get-url?url=' + estudio.estudio.informeurl, { responseType: 'blob' })
            if(data.size < 1000) {
                toast.add({severity:'warn',summary:'Atencion',detail:'Estudio no disponible.',life:1500})
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    let link = document.createElement('a');
                    link.href = base64data as string;
                    link.download = `Diagnostico Por Imagen` + '.pdf';
                    link.click();
                }
            }
        }

        const obtenerRegistros = async (isNew = false) => {
            try {
                
                const archivesNames : Record<PublicArchiveTypes,string> = {
                    LAB:'Laboratorio',
                    RX:'Radiologia',
                    TC:'Tomografia',
                    EC:'Ecografia',
                    QX:'Parte Quirurgico',
                    AP:'Anatomia Patologica',
                    PAP:'Papanicolau',
                    RMN:'Resonancia Magnetica Nuclear',
                    EDC:'Eco Doppler Cardiaco',
                    ESC:'Eco Stress Cardiaco',
                    EVC:'Eco Doppler de vasos de cuello',
                    EDV:'Eco Doppler venoso',
                    EDA:'Eco Doppler arterial',
                    HOL:'Holter',
                    MAP:'Presurometria (MAPA)',
                    ERC:'Ergonometria computarizada',
                    TCM:'Test de caminata de 6 minutos',
                    CEN:'Centellograma',
                }

                

                const filtrosTipo : Record<string,any> = {
                    Todo: {$or:[
                        {tipo:'PAC'},
                        {
                            $and:[
                                {$or:[
                                    {tipo:'HC'},
                                    ...PUBLIC_ARCHIVE_TYPES.map((tipo:string) => ({tipo}))
                                ]},
                                {'datos.isPublicable':true},
                                {'datos.datosPaciente.tipoCobertura':{$ne:'ART'}}
                            ]
                        }
                    ]},
                    Estudios: {$or:[{tipo:'HC'}, ...PUBLIC_ARCHIVE_TYPES.map((tipo:string) => ({tipo}))], 'datos.isPublicable':true},
                    Imagenes: {tipo:'PAC'}
                }

                const query = encodeURIComponent(JSON.stringify({...(filtrosTipo[tipoSeleccionado.value] || {})}));
                
                const { data } = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${pagina.value}/${itemsPorPagina.value}?query=${query}`);

                for(let dato of data.registros){
                    if(PUBLIC_ARCHIVE_TYPES.includes(dato.tipo)){
                        dato.datos.nombre = archivesNames[dato.tipo as PublicArchiveTypes];
                    }
                    if(dato.tipo === 'PAC'){
                        dato.datos.nombre = 'Diagnostico Por Imagen';
                    }
                }
                
                
                evolucionesList.value = evolucionesList.value && !isNew ? [...evolucionesList.value, ...data.registros] : data.registros;
                cantidadResultados.value = data.count;
                especialidades.value = data.especialidad;
                medicos.value = data.medicos;

                tipoSeleccionado.value !== 'Imagenes' && await obtenerEstudiosLabo();
                
            } catch (error) {
                console.log(error);
            }
        }

        const obtenerEstudiosLabo = async () => {
            try {
                const { data } = await axios.get('/lab-estudio/get-all', {
                    params:{
                        page:pagina.value,
                        pageSize:itemsPorPagina.value,
                        filters:{
                            idpaciente:store.state.pacienteLogueado.id,
                            validacion:{ne:null},
                            idturno:{ne:null},
                        },
                        includes:JSON.stringify([{
                            model:'labResultado',
                            attributes:['id'],
                            include:[{
                                model:'labDeterminacion',
                                as:'analito',
                                attributes:['id'],
                                include:[{
                                    required:true,
                                    model:'prestacion',
                                    as: 'prestacionDeterminacion',
                                    attributes:['entregaenpersona']
                                }]
                            }]
                        }]),
                        order:JSON.stringify([['fecha','DESC']])
                    }
                })

                evolucionesList.value = [...evolucionesList.value, ...data
                .filter((item:any) => !item.labResultados.some((resultado:any) => resultado?.analito?.prestacionDeterminacion?.entregaenpersona))
                .map((item:any) => ({
                    ...item, 
                    tipo:'LABO',
                    medico:item.medicovalidador,
                    datos:{...item, nombre:'Laboratorio'}}))
                ]

                evolucionesList.value.sort((a:any,b:any) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
            } catch (error) {
                console.log(error);
            }
        }

        const cantidadPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })
        
        onBeforeMount(() => {
            obtenerRegistros();
        })


        


        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                pagina.value += 1;
                if(cantidadPaginas.value >= pagina.value){
                    
                    obtenerRegistros();
                }
            }
        }

        
        
        
        
        return {
            contenedorResultados,
            resultadoFormActive,
            medicos,
            medicoSeleccionado,
            especialidades,
            especialidadSeleccionada,
            fecha,
            evolucionesList,
            evolucionSeleccionada,
            tipos,
            tipoSeleccionado,
            handleVerClick,
            handleTipoChange,
            handleVisualizar,
        }
    }

})
</script>

<style>
.popup-visorEstudios.p-dialog .p-dialog-content {
    height: 100%;
}
.resultados .animate {
    animation: entrada-resultados .2s ease forwards;
}
@keyframes entrada-resultados {
    0%{
        opacity: 0;
        
    }100%{
        opacity: 1;
        
    }
}
</style>